import React from 'react'
// import PropTypes from 'prop-types';

export default function SafeSpan ({ className = '', content }) {
  return (
    <span className={`common-safe-span ${className}`} dangerouslySetInnerHTML={{ __html: content }} />
  )
};

SafeSpan.propTypes = {}
SafeSpan.defaultProps = {}
