import React, { useState, useRef, useEffect } from 'react'
import './AKForm.scss'

const isFormValid = form => {
  if (form.email && form.zip) return true
  return true
}

// https://github.com/demand-progress/freedomfromfb-www/blob/master/js/containers/ActionForm.jsx
const sendFormToActionKit = (fields, url) => {
  // iFrame
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.setAttribute('name', 'actionkit-iframe')
  document
    .body
    .appendChild(iframe)

  // Form
  const form = document.createElement('form')
  form.style.display = 'none'
  form.setAttribute('action', 'https://act.demandprogress.org/act/')
  form.setAttribute('method', 'post')
  form.setAttribute('target', 'actionkit-iframe')
  document
    .body
    .appendChild(form)

  Object
    .keys(fields)
    .forEach(function (key) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      input.value = fields[key]
      form.appendChild(input)
    })

  form.submit()
  return true
}

export default function ({ slug, onChange, onPetitionSent, onFocus, isFocused, petition }) {
  if (!slug) return null
  const [form, setForm] = useState({
    name: '',
    petition,
    email: '',
    zip: ''
  })
  const [disabled, setDisabled] = useState(true)
  const firstFieldRef = useRef(null)
  useEffect(() => {
    if (isFocused && firstFieldRef.current) {
      firstFieldRef.current.focus()
    }
  }, [isFocused])
  const formUpdate = field => {
    return evt => {
      const obj = {}
      obj[field] = evt.target.value
      const st8 = Object.assign({}, form, obj)
      setForm(st8)
      if (onChange) {
        onChange(st8)
      }
      setDisabled(!isFormValid(st8))
    }
  }
  const submitForm = () => {
    const fields = Object.assign({}, {
      action_user_agent: navigator.userAgent,
      country: 'United States',
      form_name: 'act',
      js: 1,
      opt_in: 1,
      page: slug,
      source: 'website'
    }, form)
    if (sendFormToActionKit(fields)) {
      onPetitionSent(form)
    }
  }
  const cn = 'text-xl text-black mb-3 block w-full rounded-lg bg-white-off border-transparent    focus:border-gray-500 focus:bg-white focus:ring-0'
  return <div className='ak-form p-6' >
    <input className={cn} type='email' ref={firstFieldRef} onFocus={onFocus} value={form.email} onChange={formUpdate('email')} placeholder='email' />
    <input className={cn} type='text' onFocus={onFocus} value={form.zip} onChange={formUpdate('zip')} placeholder='zip' />
    {petition && <textarea className={`${cn} text-lg leading-tight h-20 mt-5`} onFocus={onFocus} value={form.petition} onChange={formUpdate('petition')} placeholder='petition'/>}
    <button className='font-btn disabled:opacity-80 inline-block h-14 text-2xl rounded-lg relative pt-3 p-5 uppercase text-black btn-color-alt' onClick={submitForm} disabled={disabled}>Sign the Petition</button>
  </div>
}
