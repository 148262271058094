import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import "./WpContent.scss"

export default class WpContent extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    showTitle: PropTypes.bool,
    onLoad: PropTypes.func,
    className: PropTypes.string
  }
  state = {

  }
  componentDidMount() {
    if (this.props.id) {
      fetch('https://api.demandprogressaction.org/wp-json/wp/v2/pages/' + this.props.id)
        .then(res => res.json())
        .then(res => {
          const ret = {
            content: res.content.rendered,
            title: res.title.rendered
          } 
          this.setState(ret)
          if (this.props.onLoad) {
            this.props.onLoad(res)
          }
        })
    }
  }

  render() {
    const { id, showTitle, className } = this.props
    const { content, title } = this.state
    let t = null
    if (showTitle) {
      t = <h1>{title}</h1>
      if (/:/.test(title)) {
        t = <>
          <h1>{title.split(':')[0]}:</h1>
          <h2>{title.split(':')[1]}:</h2>
        </> 
      }
    }
    return (
      <div className={"common-wp-content " + className} id={'content_' + id}>
        {t}
        <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
        {this.props.children}
      </div>
    )
  }
}
