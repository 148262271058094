import './App.scss';
import WpContent from './WpContent';
import { useState } from 'react';
import AKForm from './AKForm';
import { path } from 'ramda';
import BgVideo from './bg.mp4'
import TweetButton from './TweetButton';
import SafeSpan from './SafeSpan';

function App() {
  const [info, setInfo] = useState({})
  const [isFocused, setIsFocused] = useState(false)
  const [signed, setSigned] = useState(false)
  const petition = path(['acf', 'petition'], info)
  let show
  if (signed) {
    show = <div className='bg-black'>
      <SafeSpan className='block mt-4 p-6 pb-0' content={info?.acf?.after_action_message} />
        {/* <TweetButton
        site={info.acf}
        zip={signed.zip}
      /> */}
    </div> 
  } else if (petition) {
    show = <div>
      <AKForm 
        slug='shrink-big-tech' 
        petition={petition}
        isFocused={isFocused}
        onPetitionSent={(info) => {
          console.log(info)
          setSigned(info)
        }} 
      />
      <p className='text-sm text-gray-2 p-6 pt-0 italic leading-tight'>
        We do not share your email address without your permission. Demand Progress and Watchdog.net may send you updates on this and other important campaigns by email. If at any ;me you would like to unsubscribe from our email list, you may do so.
      </p>
    </div> 
  }
  return (
    <div className="App  min-h-screen text-white min-w-full">
      <header className='flex flex-col justify-center bg-black'>
        <video autoPlay loop muted >
          <source src={BgVideo} />
        </video>
        <h1 className="font-hero main uppercase">
          Shrink Big Tech
        </h1>
      </header>
      <div className='main text-xl md:text-2xl container mx-auto leading-snug md:leading-tight'>
        <a onClick={() => {
          document.getElementById('sign').scrollIntoView({ behavior: 'smooth', block: 'start' })
          setIsFocused(true)
        }} className="z-10 relative font-btn inline-block h-14 rounded-lg -top-7 pt-3 p-5 uppercase text-black btn-color">
          Click to sign the petition
        </a>
        <WpContent 
          id={1190} 
          showTitle={false} 
          className='px-6 text-center'
          onLoad={setInfo}
        />
        <div id='sign' />
        {show}
        {info.acf && <SafeSpan className='block below-form p-6 text-center below-form' content={info.acf.below_form} />}
        {!signed && <a onClick={() => {
          document.getElementById('sign').scrollIntoView({ behavior: 'smooth', block: 'start' })
          setIsFocused(true)
        }} className="font-btn inline-block h-14 rounded-lg relative pt-3 p-5 mb-12 uppercase text-black btn-color">
          Click to sign the petition
        </a>}
      </div>
    </div>
  );
}

export default App;
